<script>
import Multiselect from "vue-multiselect";
//import required from "vuelidate/lib/validators";
import baixa from "./baixa.vue";
export default {
  components: { baixa, Multiselect },
  data() {
    return {
      // fields para inclusãode parcelas;
      fields: [
        { label: "Código", key: "codigo", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Historico", key: "historico", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Vencimento", key: "vencimento", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Pagamento", key: "pagamento", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Juros", key: "juros", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Pagos", key: "pagos", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Resta", key: "resta", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Emitido", key: "emitido", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Quitado", key: "quitado", tdClass: 'text-right', thClass: 'text-center' },
        // { label: "Pago em", key: "pago_em", tdClass: 'text-right', thClass: 'text-center' },
        // { label: "Banco Cheque", key: "banco_cheque", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      // fildes para inclusão de contas a receber.
      fields1: [
        { label: "Código Fatura", key: "codigo_fatura", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Cliente", key: "cliente", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Número do Documento", key: "n_documento", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Data da Fatura", key: "data_fatura", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Data da Parcela", key: "data_parcela", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Quantidades de dias", key: "quantidade_dias", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Quantidade de Parcelas", key: "qtd_parcelas", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ativo", key: "ativo", tdClass: 'text-right', thClass: 'text-center' },
        // { label: "Quitado", key: "quitado", tdClass: 'text-right', thClass: 'text-center' },
        // { label: "Pago em", key: "pago_em", tdClass: 'text-right', thClass: 'text-center' },
        // { label: "Banco Cheque", key: "banco_cheque", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      loader: { get: false },
      titleBody: 'Incluir Conta a Receber',

      show: false,
      pagamentos: [
        '1',
        '2',
        '2',
        '2',
      ],
      contas_receber: [],
      contas_recebe_add: [],
      receber: {
        codigo: 1,
        codigo_fatura: 1,
        cliente: 'vinicius',
        cod_cliente: 1,
        n_documento: 1,
        data_fatura: '2023-08-09T15:12',
        data_parcela: '2023-08-09T15:12',
        quantidade_dias: 2,
        qtd_parcelas: 2,
        historico_cod: 1,
        historico: 'nada',
        vencimento: 'nada',
        emissao: '2023-08-09T15:12',
        ativo: 1,
        centro_custo: 2,
        pagamento: 1,
        valor: 2,
        juros: 3,
        pagos: 2,
        resta: 1,
        emitido: '2023-08-09T15:12',
        obs: 'obs',
        quitado: 1,
      },
      receber2: {
        historico_cod: 1,
        cliente_nome: null,
        cliente_cod: 1,
        tipo_pagamento: 1,
        num_documento: 1,
        valor: 1,
        qtd_parcelas: 1,
        emissao: '2023-03-03',
        parcela: '2023-03-03',
        fatura: '2023-03-03',
        cod_cupom: 1,
        obs: 'ola',
        debito: null,
        credito: null,
      },
      plano_contas: {
        cod: null,
        ccid: null,
        debito: null,
        crid: null,
        credito: null,
        valor: null,
        ativo: null,
      },
      verifica: false,
      get_historicos: [],
      historicos_1: null,
      historicos_cod: null,
      cliente_1: null,
      cliente_cod: null,
      get_clientes: null,
      tiposPagamentos1: null,
      centros: null,
      resultados: null,
      pagamento_type:null,
      credito:null,
      debito:null,
    }
  },
  props: {
    historicos: { type: Array },
    clientes: { type: Array },
    tiposPagamentos: { tyep: Array },
    centtros: { type: Array },
    centro_resultado: { type: Array }
  },
  created() {
    this.get_historicos = this.historicos
    this.get_clientes = this.clientes
    this.tiposPagamentos1 = this.tiposPagamentos
    this.centros = this.centtros
    this.resultados = this.centro_resultado
  },
  validations() {

  },
  mounted() {
    this.setNewTitle();
    console.log(this.show)
  },
  methods: {
    add_parcelas(e) {
      e.preventDefault();
      this.verifica = true
      // cria um clone de ojeto para evitar referencias.
      let objeto = { ...this.receber }
      this.contas_receber.push(objeto)
    },
    formatInput_valor_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.receber2.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.receber2.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    converte_valor(){
      let numericValue = this.receber2.valor.replace(/\D/g, '');
      this.receber2.valor = parseFloat(numericValue)
    },
    add_contas() {
      this.verifica = true
      // crie um clone para evitar referencias ,
      //  e não fazer alterações quando eu tiver digitando
      // so alteraar quando clicar em adicionar;
      let objeto = { ...this.receber }
      this.contas_recebe_add.push(objeto)
    },
    remove1() {
      // e.preventDefault();
      this.contas_receber.pop()
      if (this.contas_receber.length <= 0) {
        this.verifica = false
      }
    },
    select_hist_cod(objeto) {
      let objeto1 = objeto
      console.log(objeto1)
      this.receber.historico_cod = objeto1.id
      this.historicos_cod = objeto1
    },
    select_hist_nome(objeto) {
      let objeto1 = objeto
      this.receber.historico = objeto1.descricao
      this.historicos_1 = objeto1
    },
    select_cli_cod(objeto) {
      let objeto1 = objeto
      console.log(objeto1)
      this.receber.cod_cliente = objeto1.id
      this.cliente_1 = objeto1
      //this.cliente_cod = objeto1
    },
    customLabelComposicao({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },
    customLabelCliente({ id, pes_fantasia }) {
      return `${id} – ${pes_fantasia}`
    },
    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`
    },
    customLabelPagamento({ id, tiponome }) {
      return `${id} – ${tiponome}`
    },
    select_cli_nome(objeto) {
      let objeto1 = objeto
      this.receber.cliente = objeto1.pes_fantasia
      // this.cliente_1 = objeto1
      this.cliente_cod = objeto1
    },
    formatInput_valor_plano_contas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.plano_contas.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.plano_contas.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_juros_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.receber.juros.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.receber.juros = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_pago_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.receber.pagos.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.receber.pagos = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_resta_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.receber.resta.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.receber.resta = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    remove() {
      this.contas_recebe_add.pop()
      if (this.contas_recebe_add.length <= 0) {
        this.verifica = false
      }
    },
    fechar_modal() {
      this.show = false;
      console.log(this.show)
    },
    abrir_modal() {
      this.show = true;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.converte_valor();
      this.$emit('doPost', this.receber2);
    },
    selecionarHistorico(e){
       console.log(e)
      //recebe o id do objeto historico
       this.receber2.historico_cod = e.id
    },
    selecionarCliente(e){
       console.log(e)
      //recebe o id do objeto historico
       this.receber2.cliente_cod = e.id
       this.receber2.cliente_nome = e.pes_fantasia
    },
    selecionarDebito(e){
       console.log(e)
      //recebe o id do objeto historico
       this.receber2.debito = e.id
    },
    selecionarCredito(e){
       console.log(e)
      //recebe o id do objeto historico
       this.receber2.credito = e.id
    },
    selecionarPagamento(e){
       console.log(e)
      //recebe o id do objeto historico
       this.receber2.tipo_pagamento = e.id
    },

  },

}
</script>
<template>
  <div class="card">
    <div class="card-body">
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
      <form id="form-tipo-movimento" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.recebe1 variant="light">Contas a Receber:</b-button>
            </b-card-header>
            <b-collapse id="recebe1" visible accordion="pesquisar" role="tabpanel">
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <multiselect label="id" :options="get_historicos" v-model="historicos_cod"
                        @select="select_hist_nome($event)">
                      </multiselect>
                      
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                        @select="select_hist_cod($event)">
                      </multiselect>
                      
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="codigo" class="col-form-label">Cliente:</label>
                    <div class="col">
                      <multiselect label="id" :options="get_clientes" v-model="cliente_cod"
                        @select="select_cli_cod($event)">
                      </multiselect>
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <!-- <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                        @select="select_hist_cod($event)">
                      </multiselect> -->
                      <!-- <input v-model="receber.historico" class="form-control text-leftt" type="text"
                        placeholder="digite a descrição" id="banco"> -->
                        <multiselect v-model="historicos_1" placeholder="Selecione" label="descricao"
                        track-by="descricao" :options="get_historicos" :option-height="104"
                        :custom-label="customLabelHistorico" :show-labels="false" deselect-label="ENTER para remover"
                        select-label="Pressione ENTER"  @select="selecionarHistorico($event)" >
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.descricao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.descricao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_codigo" class="col-form-label">Cliente:</label>
                    <div class="col">
                      <!-- <multiselect label="pes_fantasia" :options="get_clientes" v-model="cliente_cod"
                        @select="select_cli_nome($event)">
                      </multiselect> -->
                      <!-- <input v-model="receber.cliente" class="form-control text-left" type="text"
                        placeholder="digite o nome do cliente" id="codigo"> -->
                        <multiselect v-model="cliente_cod" placeholder="Selecione" label="pes_fantasia"
                        track-by="pes_fantasia" :options="get_clientes" :option-height="104"
                        :custom-label="customLabelCliente" :show-labels="false" deselect-label="ENTER para remover"
                        select-label="Pressione ENTER" @select="selecionarCliente($event)">
                        <template slot="singleLabel" slot-scope="props" >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.pes_fantasia }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.pes_fantasia }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Débito:</label>
                    <div class="col">
                      <!-- <Multiselect v-model="receber2.debito" :options="centros" label="identificacao">
                      </Multiselect> -->
                      <multiselect v-model="debito" placeholder="Selecione" label="identificacao"
                        track-by="identificacao" :options="centros" :option-height="104"
                        :custom-label="customLabelComposicao" :show-labels="false" deselect-label="ENTER para remover"
                        select-label="Pressione ENTER" @select="selecionarDebito($event)">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Credito:</label>
                    <div class="col">
                      <!-- // -->
                      <!-- <Multiselect v-model="receber2.credito" :options="resultados" label="identificacao">
                      </Multiselect> -->
                      <multiselect v-model="credito" placeholder="Selecione" label="identificacao"
                        track-by="identificacao" :options="resultados" :option-height="104"
                        :custom-label="customLabelComposicao" :show-labels="false" deselect-label="ENTER para remover"
                        select-label="Pressione ENTER" @select="selecionarCredito($event)">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Forma de pagamento</label>
                    <div class="col">
                      <!-- <Multiselect v-model="receber.pagamento" :options="tiposPagamentos1" label="tiponome">
                      </Multiselect> -->
                      <multiselect v-model="pagamento_type" placeholder="Selecione" label="tiponome"
                        track-by="tiponome" :options="tiposPagamentos1" :option-height="104"
                        :custom-label="customLabelPagamento" :show-labels="false" deselect-label="ENTER para remover"
                        select-label="Pressione ENTER" @select="selecionarPagamento($event)">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.tiponome }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.tiponome }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Número do Documento</label>
                    <div class="col">
                      <input v-model="receber2.num_documento" class="form-control text-left" type="text"
                        placeholder="digite o número do documento" id="codigo">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="receber2.valor" class="form-control text-left" type="text"
                        placeholder="Digite o Valor" id="codigo" @input="formatInput_valor_info()">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Quantidade de Parcelas</label>
                    <div class="col">
                      <!-- max="5" -->
                      <input v-model="receber2.qtd_parcelas" min="1" class="form-control text-left" type="number"
                        placeholder="digite a Quantidade de Parcelas" id="codigo">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Emissão:</label>
                    <div class="col">
                      
                      <input v-model="receber2.emissao" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Data da Primeira Parcela:</label>
                    <div class="col">
                      
                      <input v-model="receber2.parcela" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Data da fatura:</label>
                    <div class="col">
                      
                      <input v-model="receber2.fatura" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Código Cupom:</label>
                    <div class="col">
                      
                      <input v-model="receber2.cod_cupom" id="searchCompras-fromData" type="text" class="form-control"
                        placeholder="Digite o Código" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="tipo_banco_orient" class="col-form-label">Observações</label>
                    <div class="col">
                      <textarea v-model="receber2.obs" name="" id="" cols="30" rows="10" class="form-control">

                    </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="tipo_banco_orient" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <div v-if="verifica">
                        <b-table striped hover :items="contas_recebe_add" responsive id="contas-receber"
                          :fields="fields1">
                          <template #cell(excluir)="row">
                            <button @click.prevent="remove(row.item)" class="btn btn-danger">Excluir</button>
                          </template>
                        </b-table>
                      </div>
                      <button class="btn btn-secondary mr-2" @click.prevent="add_contas()">Incluir</button>
                      <button class="btn btn-secondary " @click.prevent="remove()">Excluir</button>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <div class="accordion" role="tablist">
              <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar variant="light">Parcelas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <label for="cartao_in_id" class="col-form-label">Parcelas:</label>
                      <div class="col">
                        <div v-if="verifica">
                          <b-table striped hover :items="contas_receber" responsive id="contas-receber" :fields="fields">
                          </b-table>
                        </div>
                        <b-table-simple responsive>
                          <b-thead id="b-head">
                            <b-tr>
                              <b-th class="b-th">Código</b-th>
                              <b-th class="b-th">>Historico</b-th>
                              <b-th class="b-th">Vencimento</b-th>
                              <b-th class="b-th">Pagamento</b-th>
                              <b-th class="b-th">Valor</b-th>
                              <b-th class="b-th">Juros</b-th>
                              <b-th class="b-th">Pago</b-th>
                              <b-th class="b-th">Resta</b-th>
                              <b-th class="b-th">Emitido</b-th>
                              <b-th class="b-th">Quitado</b-th>
                              <b-th class="b-th">&nbsp;</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-td>
                                <input id="espaco" v-model="receber.codigo" type="text" class="form-control text-left">
                              </b-td>
                              <b-td>
                                <input id="espaco" v-model="receber.historico" type="text" class="form-control text-left">
                              </b-td>
                              <b-td id="espaco">
                                <input id="espaco" v-model="receber.vencimento" type="text"
                                  class="form-control text-left">
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <Multiselect selectLabel="" v-model="receber.pagamento" :options="tiposPagamentos1"
                                    label="tiponome">

                                  </Multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <input id="espaco" v-model="receber.valor" type="text" class="form-control text-right"
                                  @input="formatInput_valor_info()">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_juros_parcelas()" id="espaco" v-model="receber.juros"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_pago_parcelas()" id="espaco" v-model="receber.pagos"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_resta_parcelas()" id="espaco" v-model="receber.resta"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input id="espaco" v-model="receber.emitido" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input id="espaco" v-model="receber.quitado" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="add_parcelas($event)">Incluir</button>
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="remove($event)">Excluir</button>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-card-body>
                  </b-collapse>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar1 variant="light">Plano de Contas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar1" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <div class="form-row form-group" role="group">
                        <div class="col-sm-12 col-md-12" id="desc_ID">
                          <div class="form-group">
                            <label for="cartao_in_id" class="col-form-label">Plano:</label>
                            <div class="col">
                              <b-table-simple responsive>
                                <b-thead id="b-head">
                                  <b-tr>
                                    <b-th class="b-th">Código</b-th>
                                    <b-th class="b-th">C.C ID</b-th>
                                    <b-th class="b-th">Débito</b-th>
                                    <b-th class="b-th">C.R Id</b-th>
                                    <b-th class="b-th">Crédito</b-th>
                                    <b-th class="b-th">Valor</b-th>
                                    <b-th class="b-th">Ativo</b-th>
                                    <b-th class="b-th">&nbsp;</b-th>
                                  </b-tr>
                                </b-thead>
                                <b-tbody>
                                  <b-tr>
                                    <b-td>
                                      <input v-model="plano_contas.cod" id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <input v-model="plano_contas.ccid" id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <!-- <div class="custom-multiselect-wrapper">
                                        <multiselect v-model="plano_contas.debito" :options="centros" label="identificacao">
                                        </multiselect>
                                      </div> -->
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect :options="res" label="identificacao">
                                        </multiselect> -->
                                        <multiselect v-model="plano_contas.debito" placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="centros"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input v-model="plano_contas.crid" id="espaco" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect v-model="plano_contas.credito" :options="resultados" label="identificacao">
                                        </multiselect> -->
                                        <multiselect v-model="plano_contas.credito" placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="resultados"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" v-model="plano_contas.valor" type="text"
                                        class="form-control text-right" @input="formatInput_valor_plano_contas()">
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" v-model="plano_contas.ativo" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <button class="btn btn-secondary">Incluir</button>
                                    </b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </form>
            </div>
          </b-card-body>
        </b-card>
      </form>
      <div class="card-body" v-if="show">
        <baixa @fechar_modal="fechar_modal" :tiposPagamentos1="tiposPagamentos1" :show="show" />
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
        <button class="btn btn-light ml-4" @click="abrir_modal()">Baixar</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#espaco {
  width: 100px
}

@media (max-width: 767.98px) {
  .custom-multiselect-width .multiselect__content-wrapper {
    width: 200px;
    /* Ajuste a largura conforme necessário */
  }
}

/* Corrija a posição do menu suspenso do Multiselect */
.custom-multiselect-width .multiselect__content {
  position: absolute !important;
  z-index: 9999;
}

.custom-multiselect-wrapper {
  width: 200px;
  /* Ajuste a largura conforme necessário */
}

/* Ajuste para a tabela responsiva */
.b-table-simple__table-wrapper {
  overflow-x: auto;
}</style>